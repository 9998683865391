.loading {
  width: 100vw;
  height: 100vh;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading > div {
  width: 10vmax;
  height: 10vmax;
  border-bottom: 5px solid rgb(110, 110, 110);
  border-radius: 50%;

  animation: loadingRotate 800ms linear infinite;
}

@keyframes loadingRotate {
  to {
    transform: rotateZ(-360deg);
  }
}
